import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";

import {
  AdminBrandEngine,
  AdminBrandModel,
  AdminBrandYear,
  AdminCall,
  AdminCatalog,
  AdminCategory,
  AdminEco,
  AdminFeedback,
  AdminLogin,
  AdminModel,
  AdminNews,
  AdminRcPlus,
  AdminService,
  AdminStage,
  AdminStage2,
  AdminStock,
  AdminTransmission,
  AdminWork,
} from "../admin/pages";
import AdminBaseCatalog from "../admin/pages/AdminBaseCatalog";
import AdminBrand from "../admin/pages/AdminBrand";
import Loader from "../client/components/ui/loader/loader";
import AdminTag from "../admin/pages/AdminTag";
import AdminVideos from "../admin/pages/AdminVideos";
// Lazy loading client pages
const Contact = lazy(() => import("../client/pages/Contact"));
const ErrorPage = lazy(() => import("../client/pages/ErrorPage"));
const ExhaustSystems = lazy(() => import("../client/pages/ExhaustSystems"));
const FilterPages = lazy(() => import("../client/pages/FilterPages"));
const Home = lazy(() => import("../client/pages/Home"));
const ModelPage = lazy(() => import("../client/pages/ModelPage"));
const ModelSeries = lazy(() => import("../client/pages/ModelSeries"));
const NewsCard = lazy(() => import("../client/pages/NewsCard"));
const NewsPages = lazy(() => import("../client/pages/NewsPages"));
const Service = lazy(() => import("../client/pages/Service"));
const StockDetail = lazy(() => import("../client/pages/StockDetail"));
const StockPages = lazy(() => import("../client/pages/StockPages"));
const WorksPages = lazy(() => import("../client/pages/WorksPage"));
const WorksCard = lazy(() => import("../client/pages/WorkCard"));
const About = lazy(() => import("../client/pages/About"));
const FileService = lazy(() => import("../client/pages/FileService"));
const CatalizatorPages = lazy(() => import("../client/pages/CatalizatorPages"));
const AskPages = lazy(() => import("../client/pages/AskPages"));
const ArticlesPages = lazy(() => import("../client/pages/Articles"));
const CommentPages = lazy(() => import("../client/pages/CommentPages"));
const ModelDetails = lazy(() => import("../client/pages/ModelDetails"));
const EcoPages = lazy(() => import("../client/pages/EcoPages"));
const MotorDetail = lazy(() => import("../client/pages/MotorDetail"));
const Videos = lazy(() => import("../client/pages/Videos"));

export const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <Suspense fallback={<Loader />}>
      <ErrorPage />
    </Suspense>,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: "contact",
        element: (
          <Suspense fallback={<Loader />}>
            <Contact />
          </Suspense>
        ),
      },
      {
        path: "news",
        element: (
          <Suspense fallback={<Loader />}>
            <NewsPages />
          </Suspense>
        ),
      },
      {
        path: "works",
        element: (
          <Suspense fallback={<Loader />}>
            <WorksPages />
          </Suspense>
        ),
      },
      {
        path: "service",
        element: (
          <Suspense fallback={<Loader />}>
            <Service />
          </Suspense>
        ),
      },
      {
        path: "news/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <NewsCard />
          </Suspense>
        ),
      },
      {
        path: "work/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <WorksCard />
          </Suspense>
        ),
      },
      {
        path: "about",
        element: (
          <Suspense fallback={<Loader />}>
            <About />
          </Suspense>
        ),
      },
      {
        path: "file",
        element: (
          <Suspense fallback={<Loader />}>
            <FileService />
          </Suspense>
        ),
      },
      {
        path: "systems",
        element: (
          <Suspense fallback={<Loader />}>
            <ExhaustSystems />
          </Suspense>
        ),
      },
      {
        path: "video",
        element: (
          <Suspense fallback={<Loader />}>
            <Videos />
          </Suspense>
        ),
      },
      {
        path: "systems/:slug/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <ModelPage />
          </Suspense>
        ),
      },
      {
        path: "systems/models/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <ModelSeries />
          </Suspense>
        ),
      },
      {
        path: "filter",
        element: (
          <Suspense fallback={<Loader />}>
            <FilterPages />
          </Suspense>
        ),
      },
      {
        path: "stock",
        element: (
          <Suspense fallback={<Loader />}>
            <StockPages />
          </Suspense>
        ),
      },
      {
        path: "stock/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <StockDetail />
          </Suspense>
        ),
      },
      {
        path: "catalizator",
        element: (
          <Suspense fallback={<Loader />}>
            <CatalizatorPages />
          </Suspense>
        ),
      },
      {
        path: "ask",
        element: (
          <Suspense fallback={<Loader />}>
            <AskPages />
          </Suspense>
        ),
      },
      {
        path: "articles/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <ArticlesPages />
          </Suspense>
        ),
      },
      {
        path: "comment",
        element: (
          <Suspense fallback={<Loader />}>
            <CommentPages />
          </Suspense>
        ),
      },
      {
        path: "/:brand/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <ModelDetails />
          </Suspense>
        ),
      },
      {
        path: "/:brand_name/:model_name/:engine_id",
        element: (
          <Suspense fallback={<Loader />}>
            <EcoPages />
          </Suspense>
        ),
      },
      {
        path: "/chip-tuning/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <MotorDetail />
          </Suspense>
        ),
      },

      // Admin router
      {
        path: "admin-login",
        element: <AdminLogin />,
      },
      {
        path: "admin-news",
        element: <AdminNews />,
      },
      {
        path: "admin-work",
        element: <AdminWork />,
      },
      {
        path: "admin-category",
        element: <AdminCategory />,
      },
      {
        path: "admin-service",
        element: <AdminService />,
      },
      {
        path: "admin-feedback",
        element: <AdminFeedback />,
      },
      {
        path: "admin-model",
        element: <AdminModel />,
      },
      {
        path: "admin-catalog",
        element: <AdminCatalog />,
      },
      {
        path: "admin-call",
        element: <AdminCall />,
      },
      {
        path: "admin-stock",
        element: <AdminStock />,
      },
      {
        path: "admin-catalogs",
        element: <AdminBaseCatalog />,
      },
      {
        path: "admin-brand",
        element: <AdminBrand />,
      },
      {
        path: "admin-brand-model",
        element: <AdminBrandModel />,
      },
      {
        path: "admin-brand-year",
        element: <AdminBrandYear />,
      },
      {
        path: "admin-brand-engine",
        element: <AdminBrandEngine />,
      },
      {
        path: "admin-eco",
        element: <AdminEco />,
      },
      {
        path: "admin-stage",
        element: <AdminStage />,
      },
      {
        path: "admin-stage2",
        element: <AdminStage2 />,
      },
      {
        path: "admin-transmission",
        element: <AdminTransmission />,
      },
      {
        path: "admin-rc-plus",
        element: <AdminRcPlus />,
      },
      {
        path: "admin-tag",
        element: <AdminTag />,
      },
      {
        path: "admin-videos",
        element: <AdminVideos />,
      }
    ],
  },
]);
