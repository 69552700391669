import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function Sidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleSidebar = () => setIsSidebarOpen(false);
  return (
    <div>
      <button
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ml-3 absolute text-sm text-[#fff] rounded-lg sm:hidden"
        onClick={toggleSidebar}
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="#fff"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>
      <aside
        id="default-sidebar"
        className={`fixed top-0 left-0 z-40 w-[70%] sm:w-[30%] md:w-[25%] lg:w-[15%] h-screen transition-transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-screen relative px-3 py-1 overflow-y-auto bg-[#24303f] dark:bg-[#24303f]">
          <ul className="space-y-6 font-medium h-[32px]">
            {/* logo */}
            <li className="w-full h-full flex justify-start items-start my-8 px-3 pb-12">
              <img src="/favicon.svg" alt="" className="w-[150px] h-[50px]" />
            </li>
          </ul>
          <ul className="space-y-4 font-medium mt-[24px] h-[78%] sm:h-[88%] overflow-y-auto">
            {/* news */}
            <li>
              <NavLink
                to={"/admin-news"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">Новости</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin-tag"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white flex items-center  text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 flex items-center text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="mx-3">Теги</span>
                <span className="text-green-500 border-green-500 border rounded-2xl text-sm px-4">new</span>
              </NavLink>
            </li>
            {/* work */}
            <li>
              <NavLink
                to={"/admin-work"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">Примеры работ</span>
              </NavLink>
            </li>
            {/* catalog */}
            {/* <li>
              <NavLink
                to={"/admin-catalogs"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">Каталог</span>
              </NavLink>
            </li> */}
            {/* brand */}
            {/* <li>
              <NavLink
                to={"/admin-brand"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">Бренд</span>
              </NavLink>
            </li> */}
            {/* Category */}
            <li>
              <NavLink
                to={"/admin-category"}
                onClick={handleSidebar}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">Категория</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin-service"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="flex-1 ml-3 whitespace-nowrap">Сервис</span>
                {/* <span className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">Pro</span> */}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin-feedback"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">Обратная связь</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin-model"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">Модель</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin-catalog"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">Под-модель</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin-call"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">Вызов</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin-stock"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">Акции</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin-videos"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out space-x-3"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out space-x-3"
                }
              >
                <span className="ml-3">Видео</span>
                <span className="text-green-500 border-green-500 border rounded-2xl text-sm px-4">new</span>

              </NavLink>
            </li>

            {/* Модель бренда */}
            {/* <li>
              <NavLink
                to={"/admin-brand-model"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">Модель бренда</span>
              </NavLink>
            </li> */}
            {/* Год бренда */}
            {/* <li>
              <NavLink
                to={"/admin-brand-year"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">Год бренда</span>
              </NavLink>
            </li> */}
            {/* Брендовый двигатель */}
            {/* <li>
              <NavLink
                to={"/admin-brand-engine"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">Бренд. двигатель</span>
              </NavLink>
            </li> */}

            {/* ECO */}
            {/* <li>
              <NavLink
                to={"/admin-eco"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">ECO</span>
              </NavLink>
            </li> */}

            {/* Этап 1*/}
            {/* <li>
              <NavLink
                to={"/admin-stage"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">Этап 1</span>
              </NavLink>
            </li> */}

            {/* Этап 2*/}
            {/* <li>
              <NavLink
                to={"/admin-stage2"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">Этап 2</span>
              </NavLink>
            </li> */}

            {/* Коробка передач*/}
            {/* <li>
              <NavLink
                to={"/admin-transmission"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">Коробка передач</span>
              </NavLink>
            </li> */}

            {/* RC +*/}
            {/* <li>
              <NavLink
                to={"/admin-rc-plus"}
                className={({ isActive }) =>
                  !isActive
                    ? "text-white text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                    : "text-yellow-300 text-base md:text-xl font-medium font-montserrat hover:pl-3 transition-all duration-300 ease-in-out"
                }
              >
                <span className="ml-3">RC +</span>
              </NavLink>
            </li> */}
          </ul>
          <button
            onClick={toggleSidebar}
            className="absolute bottom-[3%] flex justify-end w-full right-[2%]"
          >
            <a className="flex sm:hidden items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <svg
                className="flex-shrink-0 w-5 h-5 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 16"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                />
              </svg>
              {/* <span className="flex-1 ml-3 whitespace-nowrap">Закрывать</span> */}
            </a>
          </button>
        </div>
      </aside>
    </div>
  );
}

export default Sidebar;
