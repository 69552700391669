import React, { useEffect, useState } from "react";
import "./index.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BASE_URL, instance } from "../../../api/axios";
import toast from "react-hot-toast";
import { ArrowRight, Clear } from "@mui/icons-material";

const EditNews = ({ setIsOpen, getData, id }) => {
  const [name, setName] = useState("");
  const [files, setFiles] = useState([]);
  const [videos, setVideos] = useState([]);
  const [text, setText] = useState("");
  const [oldImages, setOldImages] = useState([]);
  const [oldVideos, setOldVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tagId, setTagId] = useState("");
  const [tags, setTags] = useState()

  const closeHandler = () => setIsOpen(false);

  const addFiles = (e, isVideo) => {
    const newFiles = Array.from(e.target.files);
    if (isVideo) {
      setVideos([...videos, ...newFiles]);
    } else {
      setFiles([...files, ...newFiles]);
    }
  };

  const removeFile = (index, isVideo = false) => {
    if (isVideo) {
      const newVideos = [...videos];
      newVideos.splice(index, 1);
      setVideos(newVideos);
    } else {
      const newFiles = [...files];
      newFiles.splice(index, 1);
      setFiles(newFiles);
    }
  };

  const editNews = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const formData = new FormData();
      files.forEach((file) => formData.append("images", file));
      videos.forEach((video) => formData.append("videos", video));
      formData.append("title", name);
      formData.append("description", text);
      formData.append("tagId", tagId);

      await instance.patch(`/work/${id}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      getData();
      setIsOpen(false);
      toast.success("Success");
    } catch (error) {
      setError("Failed to update news entry with new images.");
      console.error(error);
      toast.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const getPrevData = async () => {
    try {
      const { data } = await instance.get(`/work/${id}/`);
      const res2 = await instance.get('/tag')
      setTags(res2.data)
      console.log(data)
      setName(data.title);
      setText(data.description);
      setTagId(data?.tag?.id)
      if (data.images) setOldImages(data.images);
      if (data.videos) setOldVideos(data.videos);
    } catch (error) {
      setError("Failed to load news data.");
      console.error("Error details:", error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    getPrevData();
  }, [id]);

  return (
    <div className="fixed top-0 left-0 w-full h-[100vh] bg-modal flex items-center justify-center z-50">
      <form
        onSubmit={editNews}
        className="rounded-md w-[90%] md:w-[50%] p-4 overflow-y-auto lg:w-[30%] min-h-[340px] bg-white"
      >
        <div className="w-full flex items-center justify-between text-[#343434] font-semibold text-[16px]">
          <p>Изменить работу</p>
          <svg
            onClick={closeHandler}
            className="cursor-pointer"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.468 11.468C11.2593 11.6767 11.0137 11.7808 10.7314 11.7803C10.449 11.7808 10.2035 11.6767 9.99482 11.468L6.31197 7.78515L2.62913 11.468C2.42043 11.6767 2.17466 11.781 1.89182 11.781C1.60996 11.781 1.36468 11.6767 1.15599 11.468C0.947292 11.2593 0.842699 11.0138 0.842208 10.7314C0.842699 10.4491 0.947292 10.2036 1.15599 9.99486L4.83883 6.31201L1.15599 2.62916C0.947292 2.42047 0.842945 2.1747 0.842945 1.89186C0.842945 1.61 0.947292 1.36472 1.15599 1.15602C1.36468 0.94733 1.61021 0.842737 1.89256 0.842246C2.17491 0.842737 2.42043 0.94733 2.62913 1.15603L6.31197 4.83887L9.99482 1.15603C10.2035 0.94733 10.4488 0.842983 10.7307 0.842983C11.0135 0.842983 11.2593 0.94733 11.468 1.15602C11.6767 1.36472 11.7808 1.61024 11.7803 1.89259C11.7808 2.17495 11.6767 2.42047 11.468 2.62916L7.78511 6.31201L11.468 9.99486C11.6767 10.2036 11.781 10.4488 11.781 10.7307C11.781 11.0135 11.6767 11.2593 11.468 11.468Z"
              fill="#333333"
            />
          </svg>
        </div>

        <div className="w-full mt-4">
          <label htmlFor="imageInput" className="custom-file-upload w-full">
            <input
              type="file"
              id="imageInput"
              multiple
              onChange={(e) => addFiles(e, false)}
              accept="image/*"
            />
            <span>Выберите изображения</span>
          </label>
        </div>

        <div className="w-full flex flex-wrap gap-2 my-4">
          {oldImages.map((file, index) => (
            <div
              key={index}
              className="flex relative items-center justify-between w-[70px]"
            >
              <img
                src={BASE_URL + file}
                alt="file"
                className="w-[50px] h-[50px] object-cover rounded-md"
              />
            </div>
          ))}
          {files.map((file, index) => (
            <div
              key={index}
              className="flex relative items-center justify-between w-[70px]"
            >
              <img
                src={URL.createObjectURL(file)}
                alt="file"
                className="w-[50px] h-[50px] object-cover rounded-md"
              />
              <button
                type="button"
                onClick={() => removeFile(index, false)}
                className="absolute top-[-15px] right-[4px]"
              >
                <Clear sx={{ color: "#ff0000" }} />
              </button>
            </div>
          ))}
        </div>

        <div className="w-full mt-4">
          <label htmlFor="videoInput" className="custom-file-upload w-full">
            <input
              type="file"
              id="videoInput"
              multiple
              onChange={(e) => addFiles(e, true)}
              accept="video/*"
            />
            <span>Выберите видео</span>
          </label>
        </div>

        <div className="w-full flex flex-wrap gap-2 my-4">
          {oldVideos.map((video, index) => (
            <div
              key={index}
              className="flex relative items-center justify-between w-[70px]"
            >
              <video
                src={BASE_URL + video}
                className="w-[50px] h-[50px] object-cover rounded-md"
                controls
              />
            </div>
          ))}
          {videos.map((video, index) => (
            <div
              key={index}
              className="flex relative items-center justify-between w-[70px]"
            >
              <video
                src={URL.createObjectURL(video)}
                className="w-[50px] h-[50px] object-cover rounded-md"
                controls
              />
              <button
                type="button"
                onClick={() => removeFile(index, true)}
                className="absolute top-[-15px] right-[4px]"
              >
                <Clear sx={{ color: "#ff0000" }} />
              </button>
            </div>
          ))}
        </div>

        <div className="w-full mt-4">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Название новости"
            className="border rounded-md w-full p-2"
            required
          />
        </div>

        {/* Tag */}
        <div className="flex w-full my-4">
          <select
            value={tagId}
            onChange={(e) => setTagId(e.target.value)}
            className="outline-none border w-full py-2 border-[#343434] rounded-md text-[#343434] px-3 text-[15px]"
          >
            <option value="">Выберите тег</option>
            {tags?.map((tag) => (
              <option key={tag.id} value={tag.id}>
                {tag?.name} {">"} {tag?.fuelType}
              </option>
            ))}
          </select>
        </div>

        <div className="w-full mt-4">
          <CKEditor
            editor={ClassicEditor}
            data={text}
            onChange={(event, editor) => setText(editor.getData())}
          />
        </div>

        <div className="w-full flex justify-end mt-4">
          <button
            type="button"
            onClick={closeHandler}
            className="border rounded-md p-2 mr-2 bg-gray-300 hover:bg-gray-400"
          >
            Отмена
          </button>
          <button
            type="submit"
            className={`border rounded-md p-2 ${loading ? "bg-blue-300" : "bg-blue-500 hover:bg-blue-600"
              } text-white`}
            disabled={loading}
          >
            {loading ? "Сохраняем..." : "Сохранить"}
          </button>
        </div>
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </form>
    </div>
  );
};

export default EditNews;